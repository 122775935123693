import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

type Props = {
  title: string;
  description?: ReactNode;
  children: React.ReactElement;
  optional?: boolean;
};

export const FieldCard: React.FC<Props> = ({ title, description, children, optional }) => (
  <Card
    state="elevated"
    className={classNames(
      'rounded-md overflow-visible',
      title === 'Description du projet' && 'min-h-[12.25rem]',
    )}
  >
    <div className="flex items-center gap-xxs">
      <div className="text-ds-h4 font-bold text-neutral-800">{title}</div>
      {optional && <span className="font-ds-b1 text-neutral-600">Optionnel</span>}
    </div>
    {description && <div className="text-ds-b1 text-neutral-700">{description}</div>}
    <div className="mt-md">{children}</div>
  </Card>
);
