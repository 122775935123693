import React from 'react';

import dayjs from 'dayjs';
import _capitalize from 'lodash/capitalize';
import _groupBy from 'lodash/groupBy';
import { useForm } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { Checkbox } from '@travauxlib/shared/src/components/DesignSystem/components/Checkbox';
import { SlotRendezVousView } from '@travauxlib/shared/src/types/api/admin/SlotRendezVousView';
import { formatAbbreviatedFrenchDateWithoutYear } from '@travauxlib/shared/src/utils/time';

type Props = {
  slotsRendezVous: SlotRendezVousView[];
  projectSlotsRendezVous: SlotRendezVousView[];
};

const groupSlotsByDay = (slots: SlotRendezVousView[]): Record<string, SlotRendezVousView[]> =>
  _groupBy<SlotRendezVousView>(slots, value => formatAbbreviatedFrenchDateWithoutYear(value.start));

export const SlotsRendezVous: React.FC<Props> = ({ slotsRendezVous, projectSlotsRendezVous }) => {
  const form = useForm();

  const consultationSlotsGrouppedByDay = groupSlotsByDay(slotsRendezVous);
  const projectSlotsGrouppedByDay = groupSlotsByDay(projectSlotsRendezVous);

  return (
    <div>
      {Object.entries(projectSlotsGrouppedByDay).map(([day, slots]) => {
        const hasSlotsSelectedForTheDay = !!consultationSlotsGrouppedByDay[day];
        const allSlotsSelectedForTheDay =
          consultationSlotsGrouppedByDay[day]?.length === projectSlotsGrouppedByDay[day]?.length;
        const lastSlotInPast =
          slots.length > 0 ? dayjs(slots[slots.length - 1].start).isBefore(dayjs()) : true;
        return lastSlotInPast ? null : (
          <div className="border-1 rounded-xs flex mt-md" key={day}>
            <div className="bg-neutral-100 min-w-[162px] p-md pl-xs flex  items-center">
              <Checkbox
                checked={hasSlotsSelectedForTheDay}
                indeterminate={hasSlotsSelectedForTheDay && !allSlotsSelectedForTheDay}
                onClick={() => {
                  const newValue = hasSlotsSelectedForTheDay
                    ? slotsRendezVous.filter(
                        slot => formatAbbreviatedFrenchDateWithoutYear(slot.start) !== day,
                      )
                    : [...slotsRendezVous, ...projectSlotsGrouppedByDay[day]];
                  form.change('slotsRendezVous', newValue);
                }}
                id={day}
              />
              <div className="ml-xs font-bold text-ds-b1">{_capitalize(day)}</div>
            </div>
            <div className="inline-flex w-full items-center px-xs flex-wrap gap-xs py-xs">
              {slots.map(slot => {
                const isSlotSelected = !!consultationSlotsGrouppedByDay[day]?.find(
                  search => search.start === slot.start && search.end === slot.end,
                );
                const isSlotInThePast = dayjs(slot.start).isBefore(dayjs());
                return isSlotInThePast ? null : (
                  <div key={slot.start}>
                    <Button
                      variant={isSlotSelected ? 'primary' : 'secondary'}
                      onClick={() => {
                        form.change(
                          'slotsRendezVous',
                          isSlotSelected
                            ? slotsRendezVous.filter(
                                slotConsultation =>
                                  slotConsultation.start !== slot.start &&
                                  slotConsultation.end !== slot.end,
                              )
                            : [...slotsRendezVous, slot],
                        );
                      }}
                    >
                      {dayjs(slot.start).format('HH[:]mm')}
                    </Button>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
