import React from 'react';

import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';

export const GestionFacturation: React.FC = () => (
  <div className="flex-grow h-full flex items-center justify-center gap-md font-bold text-ds-h3 text-center">
    <Card to="apporteur/commissions-actives">
      Facturation Apporteur <br /> d'affaires
    </Card>
    <Card to="" disabled className="min-h-[7.5rem] flex items-center justify-center">
      Facturation CDPL <br /> (en cours de développement)
    </Card>
  </div>
);
