import React, { useMemo, useState } from 'react';

import dayjs from 'dayjs';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import ProjectPicto from '@travauxlib/shared/src/components/DesignSystem/assets/ProjectPicto.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { EmptyState } from '@travauxlib/shared/src/components/DesignSystem/components/EmptyState';
import { Pagination } from '@travauxlib/shared/src/components/DesignSystem/components/Pagination';
import { RadioButtonsList } from '@travauxlib/shared/src/components/DesignSystem/components/RadioButtonsList';
import { Props as TagProps } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { ItemLink } from '@travauxlib/shared/src/components/ItemLink';
import { ChantierDevis as ChantierDevisType } from '@travauxlib/shared/src/features/Chantiers/types';
import { useIsTabletOrMobile } from '@travauxlib/shared/src/hooks/useIsTabletOrMobile';
import { usePagination } from '@travauxlib/shared/src/hooks/usePagination';
import { toCalendarFormat } from '@travauxlib/shared/src/utils/time';

type Props = {
  devis: ChantierDevisType[];
};
type DevisFilterType = 'signed' | 'cancelled' | 'all';

const getDevisDetails = (
  devis: ChantierDevisType,
): {
  variant: TagProps['variant'];
  label: string;
  value: string;
} => {
  const { label, variant, value } =
    devis.dateSignature && devis.deletedAt
      ? { label: 'Annulé', variant: 'warning' as TagProps['variant'], value: devis.deletedAt! }
      : { label: 'Signé', variant: 'success' as TagProps['variant'], value: devis.dateSignature! };

  return { variant, label, value };
};
export const ChantierDevis: React.FC<Props> = ({ devis }) => {
  const [devisFilter, setDevisFilter] = useState<DevisFilterType>('signed');
  const isTabletOrMobile = useIsTabletOrMobile();

  const devisFiltered = useMemo(
    () =>
      devis
        .filter(devis => {
          if (devisFilter === 'all') {
            return true;
          }
          if (devisFilter === 'signed') {
            return !!devis.dateSignature && !devis.deletedAt;
          }
          if (devisFilter === 'cancelled') {
            return !!devis.dateSignature && !!devis.deletedAt;
          }
          return false;
        })
        .sort((devis1, devis2) => dayjs(devis2.dateSignature).diff(dayjs(devis1.dateSignature))),
    [devis, devisFilter],
  );

  const pagination = usePagination(devisFiltered, 4);

  return (
    <Card
      state="outlined"
      className="min-h-[19.875rem] flex sm-desktop:w-1/2"
      bodyClassNames="flex grow flex-col justify-between"
    >
      <div className="h-full">
        <div className="flex sm-desktop:items-center sm-desktop:flex-row flex-col justify-between mb-md">
          <span className="text-ds-h5 font-bold mb-sm sm-desktop:mb-0">Devis</span>
          <RadioButtonsList<DevisFilterType>
            value={devisFilter}
            id="devis-filter"
            onChange={setDevisFilter}
            inline={!isTabletOrMobile}
            options={[
              { label: 'Signé', value: 'signed' },
              {
                label: 'Annulé',
                value: 'cancelled',
              },

              {
                label: 'Tout',
                value: 'all',
              },
            ]}
          />
        </div>
        <div className="h-full">
          {pagination.items.length > 0 ? (
            pagination.items.map(d => {
              const { variant, label, value } = getDevisDetails(d);
              return (
                <ItemLink
                  key={d.token}
                  title={`Devis ${d.numero ? `#${d.numero}` : ''}`}
                  label={`${label} ${toCalendarFormat(dayjs(value))}`}
                  price={d.prixTotalTTC}
                  complementary={`Version ${d.version}`}
                  tag={{
                    label,
                    variant,
                  }}
                  href={d.devisLink}
                  target="_blank"
                  rightIcon={isTabletOrMobile ? undefined : <ChevronRight />}
                />
              );
            })
          ) : (
            <div className="h-full flex justify-center items-center pb-xxl">
              <EmptyState
                description={`Aucun devis ${devisFilter === 'signed' ? 'signé' : "n'a été annulé"} `}
                icon={<ProjectPicto />}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination showTotalRecords={false} {...pagination} />
      </div>
    </Card>
  );
};
